<template>
  <v-container mt-flex fluid>
  <v-container d-flex d-sm-none>
    <v-row>
      <v-col cols="12" class="pa-5">
        <h1>New Schools Coming Soon!</h1>
      </v-col>
    </v-row>
    </v-container>
    <v-row class="pa-5 bg-grey">
      <h1 class="headerGreen--text ozi-font" ><span class="onyx--text ozi-font">DM Dance CO </span>Schools and Fees</h1>
    </v-row>
    <v-row>
      <v-col cols="12" class="pa-5">
        <h1 class="ozi-font">New Schools Coming Soon!</h1>
      </v-col>
    </v-row>
    <!-- <v-row class="pa-5 bg-white" align="center">
      <v-col cols="8 offset-2">
      <v-data-table
        :headers="headers"
        :items="desserts"
        :items-per-page="5"
        class="elevation-1"
      ></v-data-table>
      </v-col>
    </v-row> -->
  </v-container>
</template>

<script>



export default {
  data () {
    return {
      headers: [
          {
            text: 'Dance Type',
            align: 'start',
            sortable: false,
            value: 'name',
          },
          { text: 'Registration New Student', value: 'registrationNew' },
          { text: 'Registratio Returning Student', value: 'registrationReturning' },
          { text: 'Termly Fee', value: 'termlyFee' },
          { text: 'Yearly Fee', value: 'yearlyFee' },
          { text: 'Two Styles Termly Fee', value: 'twoStylesTerm' },
        ],
        desserts: [
          {
            name: 'Dance Mouse Pre-Primary and Ballerina Pre-Primary',
            registrationNew: 'R250',
            registrationReturning: 'R100',
            termlyFee: 'R750',
            yearlyFee: 'R2700',
            twoStylesTerm: 'R1399',
          },
          {
            name: 'Dance Mouse Primary and Ballerina Primary',
            registrationNew: 'R250',
            registrationReturning: 'R100',
            termlyFee: 'R840',
            yearlyFee: 'R3020',
            twoStylesTerm: 'R1580',
          },
        ],

    }
  },
  methods: {

    
      
  },

  computed: {

    
  },
}
</script>
<style>

.view-enter-active {
  animation-duration: 1.5s;
  animation-name: slideIn;
  animation-iteration-count: 1;
  animation-direction: alternate;
  animation-delay: 1s ease-in;
}

@keyframes slideIn {
  from {
    opacity: 0;
    margin-left:-100%;
  }
  
  to {
    opacity: 1;
    margin-left:0%;
  }
}
iframe {
  margin-top: -30px; /* Cut off top bar */
  margin-bottom: -30px; /* Cut off bottom bar */
}
.bg-grey {
  background-color: rgba(0, 0, 0, 0.05);
}

.v-card.on-hover.theme--dark {
  transition: background-color 0.5s ease;
  background-color: rgba(#FFF, 0.8)
}
</style>